import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import Seo from '../components/seo'
import Layout from '../components/layout'
import * as styles from './blog-post.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import useWindowDimensions from '../hooks/useWindowDimensions'

// class PaardTemplate extends React.Component {

  const PaardTemplate = (props) => {  
  
    let ytvideo;
    const {width} = useWindowDimensions();
    
    const post = get(props, 'data.contentfulPaard')
    const previous = get(props, 'data.previous')
    const next = get(props, 'data.next')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(post.description.raw)
    )
    console.log(post);
    if(post.youtubeVideoId !== null && width > 768){
      const video_src = "https://www.youtube.com/embed/" + post.youtubeVideoId;
      ytvideo =             
      <iframe title='ytplayer' id="ytplayer" type="text/html" width="640" height="360"
      src={video_src}
      frameborder="0"></iframe>
    }
    else if(post.youtubeVideoId !== null && width < 768){
      const video_src = "https://www.youtube.com/embed/" + post.youtubeVideoId;
      ytvideo =             
      <iframe title='ytplayer' id="ytplayer" type="text/html" width="360" height="200"
      src={video_src}
      frameborder="0"></iframe>
    }
    else{
      ytvideo = <div></div>
    }

    console.log(post);

    return (
      <Layout location={props.location}>
        <Seo
          title={post.title}
          description={plainTextDescription}
          image={`http:${post.image.resize.src}`}
        />
        <div className='empty_box'></div>
        <GatsbyImage style={{maxWidth: '55rem', margin: '0 auto'}} image={post.image?.gatsbyImageData}/>

        <div className={styles.container}>

          <div className={styles.article}>
            <h1>{post.heading}</h1>
            <h2>{post.subheading}</h2>
            <div className={styles.body}>
              {post.description?.raw && renderRichText(post.description)}
            </div>
            <div style={{display: 'flex', jusifyContent: 'center', width: '100%'}}>
              {ytvideo}
            </div>
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/paard/${previous.slug}`} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/paard/${next.slug}`} rel="next">
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    )
}

export default PaardTemplate

export const pageQuery = graphql`
  query PaardBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulPaard(slug: { eq: $slug }) {
      slug
      heading
      youtubeVideoId
      subheading
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }

      description {
        raw
      }
    }
    previous: contentfulPaard(slug: { eq: $previousPostSlug }) {
      slug
      heading
    }
    next: contentfulPaard(slug: { eq: $nextPostSlug }) {
      slug
      heading
    }
  }
`
